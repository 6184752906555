// export const API_BASE = 'https://veloqa.com/dev/api';
// export const API_SUCCESS = 'https://veloqa.com/dev'


// export const API_BASE = 'https://neha.craftandcode.in/api'
// export const API_SUCCESS = 'https://neha.craftandcode.in'

export const API_BASE = 'https://api.veloqa.com/api';
export const API_SUCCESS = 'https://api.veloqa.com/'

// export const API_BASE = 'https://veloapi.craftandcode.in/api';
// export const API_SUCCESS = 'https://veloapi.craftandcode.in/'

